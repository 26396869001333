import React, { Component } from "react";
import AuthService from "../services/auth.service";
import { Redirect } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";
import { Spinner } from "react-bootstrap";
import axios from "axios"

import { SpinnerCircular, SpinnerDotted, SpinnerInfinity } from 'spinners-react';
import Header from "./Header";

const required = value => {
     if (!value) {
          return (
               <div className="alert alert-danger" role="alert">
                    This field is required!
               </div>
          );
     }
};

export default class EditQuestion extends Component {
     constructor(props) {
          super(props);
          this.handleUpdateQuestion = this.handleUpdateQuestion.bind(this);
          this.onChangeBody = this.onChangeBody.bind(this);
          this.onChangeOption_a= this.onChangeOption_a.bind(this);
          this.onChangeOption_b = this.onChangeOption_b.bind(this);
          this.onChangeOption_c = this.onChangeOption_c.bind(this);
          this.onChangeOption_d = this.onChangeOption_d.bind(this);
          this.onChangeCorrect_option= this.onChangeCorrect_option.bind(this);

          this.state = {
               
               loading: false,
               ViewTemplateloading: false,
               message: "",
               body: "",
               option_a: "",
               option_b: "",
               option_c: "",
               option_d: "",
               term: "",
               correct_option: "",
               class_name: ""
          };
     }

     onChangeBody(e) {
          this.setState({
               body: e.target.value
          });
     }

     onChangeOption_a(e) {
          this.setState({
               option_a: e.target.value
          });
     }
     onChangeOption_b(e) {
          this.setState({
               option_b: e.target.value
          });
     }
     onChangeOption_c(e) {
          this.setState({
               option_c: e.target.value
          });
     }
     onChangeOption_d(e) {
          this.setState({
               option_d: e.target.value
          });
     }
     onChangeCorrect_option(e) {
          this.setState({
               correct_option: e.target.value
          });
     }


    UpdateQuestion(body, option_a, option_b, option_c,option_d,correct_option) {
        const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
        const token = user.token;
        const Details_of_Question = JSON.parse(sessionStorage.getItem('Details_of_Question'));

        let formData = new FormData();
        formData.append('examination', Details_of_Question.examination);
        formData.append('body', body || Details_of_Question.body);
        formData.append('option_a', option_a || Details_of_Question.option_a);
        formData.append('option_b', option_b || Details_of_Question.option_b);
        formData.append('option_c', option_c || Details_of_Question.option_c);
        formData.append('option_d', option_d || Details_of_Question.option_d);
        formData.append('option_e', "option_constant");
        formData.append('correct_option', correct_option || Details_of_Question.correct_option);
       
        return axios.patch(`https://admin.zenithsciencecollege.com/cbt/api/question/update/${Details_of_Question.pk}/`, formData,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`,
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'POST, GET,HEAD,OPTIONS',
              'Access-Control-Allow-Credentials': true
            },
          }
        )
      }
    

     handleUpdateQuestion(e) {
          e.preventDefault();

          this.setState({
               message: "",
               loading: true,
               ViewTemplateloading: true,
          });

          this.form.validateAll();

          if (this.checkBtn.context._errors.length === 0) {
               this.UpdateQuestion(
                    this.state.body,
                    this.state.option_a,
                    this.state.option_b,
                    this.state.option_c,
                    this.state.option_d,
                    this.state.correct_option
               ).then(
                    () => {
                         window.location = "/AddExam"
                    },
                    error => {
                         const resMessage =
                              (error.response &&
                                   error.response.data.detail &&
                                   error.response.data.detail) ||
                              error.message ||
                              error.toString();

                         this.setState({
                              loading: false,
                              message: resMessage,
                              ViewTemplateloading: false,

                         });
                    }
               );
          } else {
               this.setState({
                    loading: false
               });
          }
     }

     componentDidMount() {
          

     }


     render() {
          if (!sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__')) {
               return (<Redirect to={'/login'} />)
          }
          const { Exams, loading } = this.state;
          const userInfo = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const currentStudent = userInfo.profile
          const Details_of_Question = JSON.parse(sessionStorage.getItem('Details_of_Question'));

          return (
               <div>
                    <Header />
                    <div className="wrapper position-relative">
                         <div className="wizard-content-1 clearfix">

                              <div className="step-inner-content clearfix position-relative">
                                   <Form className="multisteps-form__form"
                                        onSubmit={this.handleUpdateQuestion}
                                        ref={c => {
                                             this.form = c;
                                        }}
                                        id="wizard" >
                                        

                                        <div className="form-area position-relative">
                                             {/* step 3 */}
                                          
                                      <div className="multisteps-form__panel js-active" data-animation="fadeIn">
                                          <div className="col-md-12">
                                         
                                              <div className="job-wizard-right-area">
                                              {this.state.message && (
                                             <div className="form-group">
                                                  <div className="text-danger" >
                                                      <h4> {this.state.message}</h4>
                                                  </div>
                                             </div>
                                        )}
                                                  <div className="jw-top-title">
                                                      <h2>Please Fill in the Form</h2>
                                                      <p>Question info</p>
                                                  </div>
                                                  <div className="hire-company-form">
                                                      <div className="word-txt-area">
                                                          <label>Question</label>
                                                          <Textarea maxLength={500} name="some_word" placeholder={Details_of_Question.body}
                                                            value={this.state.body} onChange={this.onChangeBody} 
                                                           
                                                           />
                                                          <div className="text-right">500.max</div>
                                                      </div>
                                                      <div className="hire-company-form">
                                                          <label>A</label>
                                                          <div><Input maxLength={255} name="some_word" 
                                                          placeholder={Details_of_Question.option_a}
                                                          value={this.state.option_a} onChange={this.onChangeOption_a} 
                                                          
                                                          /></div>
                                                          <div className="text-right">255.max</div>
                                                          <label>B</label>
                                                          <div><Input maxLength={255} name="some_word" 
                                                          placeholder={Details_of_Question.option_b}
                                                          value={this.state.option_b} onChange={this.onChangeOption_b} 
                                                           
                                                           /></div>
                                                          <div className="text-right">255.max</div>
                                                          <label>C</label>
                                                          <div><Input maxLength={255} name="some_word" placeholder={Details_of_Question.option_c}
                                                            value={this.state.option_c} onChange={this.onChangeOption_c}
                                                            /></div>
                                                          <div className="text-right">255.max</div>
                                                          <label>D</label>
                                                          <div><Input maxLength={255} name="some_word" 
                                                          placeholder={Details_of_Question.option_d}
                                                          value={this.state.option_d} onChange={this.onChangeOption_d} 
                                                          /></div>
                                                          <div className="text-right">255.max</div>
                                                      </div>
                                                      <div className="job-app-select-option">
                                                          <ul>
                                                              <li>
                                                                  <div>
                                                                      <Select name="j_position" className="valid"
                                                                       aria-invalid="false"
                                                                       value={this.state.correct_option} onChange={this.onChangeCorrect_option}

                                                                       >
                                                                          <option>Correct Answer {Details_of_Question.correct_option}</option>
                                                                          <option value="a">A</option>
                                                                          <option value="b">B</option>
                                                                          <option value="c">C</option>
                                                                          <option value="d">D</option>
                                                                      </Select>
                                                                  </div>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                  <button className="btn btn-secondary btn-lg btn-block">

                                                      {this.state.loading ?
                                                          <Spinner animation="border" role="status">
                                                              <span className="visually-hidden">loading...</span>
                                                          </Spinner>
                                                          :
                                                          <span>Update</span>
                                                      }
                                                  </button>
                                              </div>
                                          </div>
                                      </div>

                                        </div>
                                        <CheckButton
                                             style={{ display: "none" }}
                                             ref={c => {
                                                  this.checkBtn = c;
                                             }}
                                        />
                                   </Form>
                              </div>
                         </div>
                    </div>
               </div>


          );
     }
}



















