import React from 'react'
import OptionInput from './FormElements/OptionInput'

const FormElement = ({ field: { pk, body,correct_option,examination,option_a,option_b,option_c,option_d,option_e, answer }}) => {
  
    switch (option_e) {
        case 'option_constant':
            return (<OptionInput
                pk={pk}
                body={body}
                correct_option={correct_option}
                examination={examination}
                option_a={option_a}
                option_b={option_b}
                option_c={option_c}
                option_d={option_d}
                option_e={option_e}                
                answer={answer}
 
            />)
            case 'number':
                return (<OptionInput
                    pk={pk}
                    body={body}
                    correct_option={correct_option}
                    examination={examination}
                    option_a={option_a}
                    option_b={option_b}
                    option_c={option_c}
                    option_d={option_d}
                    option_e={option_e}                
                    answer={answer}
            />)
        

        default:
            return (<OptionInput
                pk={pk}
                body={body}
                correct_option={correct_option}
                examination={examination}
                option_a={option_a}
                option_b={option_b}
                option_c={option_c}
                option_d={option_d}
                option_e={option_e}                
                answer={answer}
        />)
        }


}

export default FormElement
