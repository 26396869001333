// import React, { Component } from "react";
// import AuthService from "../services/auth.service";
import { Redirect, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import FormElement from './FormElement';
import { FormContext } from './FormContext';
import axios from 'axios'
// import Form from "react-validation/build/form";
// import Input from "react-validation/build/input";
// import CheckButton from "react-validation/build/button";
import { Spinner } from "react-bootstrap";




//hey i will use react hook here
const Exams = (props) => {


     // const form = useRef();
     // const checkBtn = useRef();

     const [get_exam_details, set_exam_details] = useState({});
     const [successful, setSuccessful] = useState(false);
     const [message, setMessage] = useState("");
     const [loading, setLoading] = useState(false);
     const [show, setShow] = useState(false);
     const [time, setTime] = useState(new Date());




     const ExamDetail = JSON.parse(sessionStorage.getItem('___ExamsStart___'));
     const call_exam_details = ExamDetail.get_questions;


     // setTimeOut(() => setShow({ show: true}), 3000)

     useEffect(() => {
          // call_exam_details form sessionStorage
          const ExamDetail = JSON.parse(sessionStorage.getItem('___ExamsStart___'));
          const call_exam_details = ExamDetail.get_questions;
          set_exam_details(call_exam_details)
          if (!sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__')) {
               return (<Redirect to={'/login'} />)
          }

          if (ExamDetail.duration === "30_minutes") {
               const delay = 1800;

               let timer1 = setTimeout(() => setShow(true), delay * 1000);

               let TimeId = setInterval(() => setTime(new Date()), 1000);



               // this will clear Timeout
               // when component unmount like in willComponentUnmount
               // and show will not change to true
               return () => {
                    clearTimeout(timer1);
                    clearInterval(TimeId);

               };
          } else if (ExamDetail.duration === "60_minutes") {
               const delay = 3600;

               let timer1 = setTimeout(() => setShow(true), delay * 1000);

               let TimeId = setInterval(() => setTime(new Date()), 1000);



               // this will clear Timeout
               // when component unmount like in willComponentUnmount
               // and show will not change to true
               return () => {
                    clearTimeout(timer1);
                    clearInterval(TimeId);

               };

          } else if (ExamDetail.duration === "120_minutes") {
               const delay = 7200;

               let timer1 = setTimeout(() => setShow(true), delay * 1000);

               let TimeId = setInterval(() => setTime(new Date()), 1000);



               // this will clear Timeout
               // when component unmount like in willComponentUnmount
               // and show will not change to true
               return () => {
                    clearTimeout(timer1);
                    clearInterval(TimeId);

               };
          }
          // const delay = 5;

          // let timer1 = setTimeout(() => setShow(true), delay * 1000);




          // this will clear Timeout
          // when component unmount like in willComponentUnmount
          // and show will not change to true



     }, [])



     const handleSubmit = (event) => {
          event.preventDefault();

          setMessage("");
          setSuccessful(false);

          setMessage("");
          setLoading(true);


          // form.current.validateAll();

          //get the user from the sessionStorage
          const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const token = user.token;

          //get single exam details
          const ExamDetail = JSON.parse(sessionStorage.getItem('___ExamsStart___'));

          //get the ExamDetail Pk  to send as require examination_pk
          const ExamDetailPk = ExamDetail.pk

          //Save all user answers of the exam  to sessionStorage
          const to_Send = JSON.parse(sessionStorage.getItem('Answers_to_Send'))

          // returns an array of a given object's own enumerable property values
          const InnerNew = [];
          const I_to_Send = Object.values(to_Send);
          for (let j = 0; j < I_to_Send.length; j++) {
               const newArray = I_to_Send[j];


               InnerNew.push(
                    {
                         question_pk: newArray.pk,
                         answer: newArray.answer
                    }
               );
          }

          //Response Body to send 
          const Responsebody =
          {
               examination_pk: ExamDetailPk,
               answers: InnerNew,

          }

          // if (checkBtn.current.context._errors.length === 0) {

          // console.log(Responsebody);
          axios.post("https://admin.zenithsciencecollege.com/cbt/api/examination-answer/create-many/", Responsebody,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },
               })
               .then(res => {

                    if (res.data) {
                         sessionStorage.setItem("Sent__Answers", JSON.stringify(res.data));
                    }
                    // setMessage(res.data.detail);
                    // setSuccessful(true);
                    window.location = "/ThankYou"

               },
                    (error) => {
                         const resMessage =
                              (error.response &&
                                   error.response.data.detail &&
                                   error.response.data.detail) ||
                              error.survey_title ||
                              error.toString();

                         setMessage(resMessage);
                         setLoading(false);

                         // setSuccessful(false);
                    }
               );
          // console.log(get_exam_details)
          // }
     }

     //this handle the change user typing
     const handleChange = (id, event) => {

          //   const newElements = { ...get_exam_details }
          const questions = [...get_exam_details];

          //second array to loop through the field
          for (let j = 0; j < questions.length; j++) {
               const field = questions[j];

               //check the pk for each field
               const { pk } = field;
               if (id === pk) {
                    field['answer'] = event.target.value;
               }
          }

          // }
          //Save the result to the sessionStorage
          sessionStorage.setItem("Answers_to_Send", JSON.stringify(get_exam_details));
     }

     const logOut = () => {
          sessionStorage.clear();
          window.location = "/"
     };


     // JSON.parse(sessionStorage.getItem('___ExamsStart___'));
     if (!sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__')) {
          return (<Redirect to={'/login'} />)
     }
     return (
          <FormContext.Provider value={{ handleChange }}>

               <div className="quiz-top-area text-center">
                    <h1>Time Allowed: {ExamDetail.duration}</h1>
                    <div className="quiz-countdown text-center ul-li">
                         <ul>

                              {/* <li className="hours">
                                   <span className="count-down-number" />
                                   <span className="count-unit">Hours</span>
                              </li>
                              <li className="minutes">
                                   <span className="count-down-number" />
                                   <span className="count-unit">Min</span>
                              </li>
                              <li className="seconds">
                                   <span className="count-down-number" />
                                   <span className="count-unit">Sec</span>
                              </li> */}
                              <h1>{time.toLocaleTimeString()}</h1>

                         </ul>

                    </div>
                    <h1><a className="fa fa-power-off fa-lg float-left"
                         href="#"
                         onClick={logOut}
                    >
                    </a>
                    </h1>
               </div>
               {show ? (


                    // <div className="wrapper position-relative">
                    // <div className="wizard-content-1 clearfix">
                    //      <div className="">
                    //           <ul className="tablist multisteps-form__progress">
                    //                <li className="multisteps-form__progress-btn js-active current" />
                    //                <li className="multisteps-form__progress-btn " />
                    //                <li className="multisteps-form__progress-btn" />
                    //                <li className="multisteps-form__progress-btn" />
                    //                <li className="multisteps-form__progress-btn" />
                    //           </ul>
                    //      </div>
                    //      <div className="step-inner-content clearfix position-relative">
                    //           <form className="multisteps-form__form" id="wizard" >

                    //                <div className="form-area position-relative">

                    //                     <div>show is false, wait {delay}seconds</div>

                    //                </div>
                    //           </form>

                    //      </div>
                    // </div>
                    // </div>

                    <div className="modal fade show" id="modal-xl" style={{ display: 'block', paddingRight: 12 }} aria-modal="true" role="dialog">
                         <div className="modal-dialog modal-xl">
                              <div className="modal-content bg-danger">
                                   <div className="modal-header">
                                        <h4 className="modal-title">Exam Time Out</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                             <span aria-hidden="true">×</span>
                                        </button>
                                   </div>
                                   <div className="modal-body">
                                        <p>Sorry the time Allowed for this Exam is Elapsed , Please Submit!</p>
                                   </div>
                                   <div className="modal-footer justify-content-between">
                                        <button onClick={handleSubmit} className="btn btn-primary btn-block" disabled={loading}>

                                             {loading ?
                                                  <Spinner animation="border" role="status">
                                                       <span className="visually-hidden">Submitting...</span>
                                                  </Spinner>
                                                  :
                                                  <span>Submit</span>
                                             }

                                        </button>                                   </div>
                              </div>
                              {/* /.modal-content */}
                         </div>
                         {/* /.modal-dialog */}
                         {message && (
                              <div className="form-group">
                                   <div
                                        className={
                                             successful ? "alert alert-success" : "alert alert-danger"
                                        }
                                        role="alert"
                                   >

                                        <h3>  {message}</h3>
                                   </div>
                              </div>
                         )}
                    </div>

               ) : (

                    <div className="wrapper position-relative">
                         <div className="wizard-content-1 clearfix">
                              <div className="">
                                   <ul className="tablist multisteps-form__progress">
                                        <li className="multisteps-form__progress-btn js-active current" />
                                        <li className="multisteps-form__progress-btn " />
                                        <li className="multisteps-form__progress-btn" />
                                        <li className="multisteps-form__progress-btn" />
                                        <li className="multisteps-form__progress-btn" />
                                   </ul>
                              </div>
                              <div className="step-inner-content clearfix position-relative">
                                   <form className="multisteps-form__form" id="wizard" >

                                        <div className="form-area position-relative">

                                             <div className="multisteps-form__panel  js-active" data-animation="fadeIn">
                                                  <div className="wizard-forms clearfix position-relative">
                                                       <div className="quiz-title text-center">
                                                            <h1>ZENITH SCIENCE COLLEGE</h1>
                                                            <h4 className="text-success">{ExamDetail.term_title}</h4>
                                                            <h6>{ExamDetail.subject_title}</h6>
                                                            <span>{ExamDetail.class_name_title}</span>

                                                            <p>Instructions</p>
                                                       </div>
                                                       <div className="quiz-option-selector clearfix">
                                                            <h5> You are required to comply with the directions given by the head invigilator at the examination venue.
                                                                 If you experience technical problems during a digital examination, you must immediately contact one of the invigilators. The invigilator will call for technical support.
                                                                 Failure to report such technical problems might be treated as cheating or an attempt to cheat.
                                                            </h5>

                                                       </div>

                                                       <br />
                                                       <center>
                                                            <div className="actions clearfix">
                                                                 <ul>
                                                                      <li className="d-none"><span className="js-btn-previous" title="PREV">Previous Question</span></li>
                                                                      <li><span className="js-btn-next" title="NEXT">Start</span></li>
                                                                 </ul>
                                                            </div>
                                                       </center>
                                                  </div>
                                             </div>
                                             {call_exam_details && call_exam_details.map((qs, i, call_exam_details) => (

                                                  <div key={i} className="multisteps-form__panel" data-animation="fadeIn">
                                                       <div className="wizard-forms clearfix position-relative">

                                                            {message && (
                                                                 <div className="form-group">
                                                                      <div
                                                                           className={
                                                                                successful ? "alert alert-success" : "alert alert-danger"
                                                                           }
                                                                           role="alert"
                                                                      >

                                                                           <h3>  {message}</h3>
                                                                      </div>
                                                                 </div>
                                                            )}
                                                            <div className="quiz-title text-center">
                                                                 <h4 className="text-success">{ExamDetail.term_title}</h4>
                                                                 <h6>{ExamDetail.subject_title}</h6>
                                                                 <span>Question {i + 1}</span>

                                                                 <p>{qs.body}</p>
                                                            </div>
                                                            <FormElement key={i} field={qs} />

                                                            {i + 1 != call_exam_details.length ?
                                                                 <div className="actions clearfix">
                                                                      <ul>
                                                                           <li><span className="js-btn-prev" title="PREV">Previous Question</span></li>
                                                                           <li><span className="js-btn-next" title="NEXT">Next Question</span></li>
                                                                      </ul>
                                                                 </div>
                                                                 :

                                                                 <div className="actions clearfix">
                                                                      <ul>
                                                                           <li><span className="js-btn-prev" title="PREV">Previous Question</span></li>

                                                                           <li>
                                                                                <button onClick={handleSubmit} className="js-btn-submit" disabled={loading}>

                                                                                     {loading ?
                                                                                          <Spinner animation="border" role="status">
                                                                                               <span className="visually-hidden">Submitting...</span>
                                                                                          </Spinner>
                                                                                          :
                                                                                          <span>Submit</span>
                                                                                     }

                                                                                </button>

                                                                                {/* <Link to="/ThankYou" className="js-btn-submit" >Submit</Link> */}
                                                                           </li>


                                                                      </ul>
                                                                 </div>
                                                            }


                                                       </div>
                                                  </div>

                                             ))}

                                        </div>

                                   </form>
                              </div>
                         </div>
                    </div>
               )
               }
               <div>

               </div>

          </FormContext.Provider>


     );
}

export default Exams;

