import React, { Component } from "react";
// import AuthService from "../services/auth.service";
import { Link, Redirect } from 'react-router-dom';
import axios from "axios"
// const required = value => {
//     if (!value) {
//         return (
//             <div className="alert alert-danger" role="alert">
//                 This field is required!
//             </div>
//         );
//     }
// };
import { SpinnerCircular, SpinnerDotted, SpinnerInfinity } from 'spinners-react';
import Header from "./Header";

export default class ViewExam extends Component {
     constructor(props) {
          super(props);

          this.state = {

               loading: true,
               loadingStart: false,
               message: "",
               Exams: []
          };
     }


     componentDidMount() {
          this.setState({
               loading: true
          })
          const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const token = user.token;
          axios.get(`https://admin.zenithsciencecollege.com/cbt/api/examination/list/`,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },

               },
          )
               .then(res => {

                    if (res.data) {
                         sessionStorage.setItem("Exams__Zenith", JSON.stringify(res.data));
                    }

                    this.setState({
                         Exams: res.data,
                         loading: false
                    })

               })
     }

     StartExam(exam) {
          const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const token = user.token;
          this.setState({
               loading: true
          });

          const examPk = exam.pk
          axios.get(`https://admin.zenithsciencecollege.com/cbt/api/examination/details/${examPk}/`,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },

               },

          )
               .then(res => {
                    window.location = "/Exams"

                    if (res.data) {
                         sessionStorage.setItem("___ExamsStart___", JSON.stringify(res.data));
                    }
               })
     }


     render() {
          
          if (!sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__')) {
               return (<Redirect to={'/login'} />)
          }
          const { Exams, loading } = this.state;
          const userInfo = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const currentStudent = userInfo.profile
          return (
               <div>
                    <Header />
                    <div className="wrapper position-relative">
                         <div className="wizard-content-1 clearfix">

                              <div className="step-inner-content clearfix position-relative">
                                   <form className="multisteps-form__form" action="thank-you.html" id="wizard" method="POST">
                                        <div className="form-area position-relative">
                                             {/* step 3 */}
                                             <div className="multisteps-form__panel js-active" data-animation="fadeIn">
                                                  <div className="wizard-forms">
                                                       <center>       <h1>Examinations</h1></center>
                                                       <div className="quiz-option-selector">
                                                       {loading ? (
                                                          <center>
                                                          <SpinnerInfinity color="green" size="30%" />
                                                            </center>
                                                     ) : (
                                                            <div className="row">
                                                                 <div className="col-md-12">
                                                                 {/* <Link to="/ExamForm"className="btn btn-primary float-right">Add</Link> */}

                                                                      <table className="table table-bordered">
                                                                           <thead>
                                                                                <tr>
                                                                                     <th style={{ width: 10 }}>SN</th>
                                                                                     
                                                                                     <th>Class</th>
                                                                                     <th>Subject</th>
                                                                                     <th>Term</th>
                                                                                     <th>Duration</th>
                                                                                     <th>Questions</th>
                                                                                     <th>Progress</th>
                                                                                     <th>Action</th>
                                                                                     {/* <th style={{ width: 40 }}>Label</th> */}
                                                                                     {/* <th >Label</th> */}
                                                                                </tr>
                                                                           </thead>
                                                                           {Exams && Exams.map((exam, i) => (

                                                                           <tbody>

                                                                                <tr key={i}>
                                                                                     <td>{i+1}</td>
                                                                                     <td>{exam.class_name_title}</td>
                                                                                     <td>{exam.subject_title}</td>
                                                                                     <td>{exam.term_title}</td>
                                                                                     <td>{exam.duration}</td>
                                                                                     <td>{exam.get_questions?exam.get_questions.length:0}</td>
                                                                                     <td>
                                                                                          <div className="progress progress-xs">
                                                                                               <div className="progress-bar progress-bar-danger" style={{ width: '55%' }} />
                                                                                          </div>
                                                                                     </td>
                                                                                     <td>
                                                                                          <Link className="fas fa-edit fa-lg text-success"></Link>&emsp;
                                                                                          <Link className="fas fa-trash fa-lg text-danger"></Link>
                                                                                     </td>
                                                                                     {/* <td><span className="badge bg-danger">55%</span></td> */}
                                                                                </tr>
                                                                               
                                                                                {/* <tr>
                                                                                     <td>4.</td>
                                                                                     <td>Fix and squish bugs</td>
                                                                                     <td>
                                                                                          <div className="progress progress-xs progress-striped active">
                                                                                               <div className="progress-bar bg-success" style={{ width: '90%' }} />
                                                                                          </div>
                                                                                     </td>
                                                                                     <td><span className="badge bg-success">90%</span></td>
                                                                                </tr> */}
                                                                           </tbody>
                                                                           ))}
                                                                      </table>
                                                                      
                                                                 </div>
                                                            </div>
                                                        )}

                                                       </div>
                                                  </div>


                                             </div>
                                        </div>
                                   </form>
                              </div>
                         </div>
                    </div>
               </div>


          );
     }
}
