import React, { Component } from "react";
// import AuthService from "../services/auth.service";
import { Link, Redirect } from 'react-router-dom';
import axios from "axios"


export default class Header extends Component {
    constructor(props) {
        super(props);
        this.routeChange = this.routeChange.bind(this);
        this.logOut = this.logOut.bind(this);

        this.state = {
         
          loading: true,
         
        };
      }
    
      logOut() {
          sessionStorage.clear();
          window.location = "/"
     }

     routeChange = () => {
          window.location = "/"
     };

      componentDidMount(){
         
      }
      
    render() {
     
     //    const {} = this.state;
        const userInfo= JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const currentStudent=userInfo.profile
        return (
          <div>
              
              <div className="quiz-top-area bg-success text-center">
             
              <h1>
               <Link  to="#" onClick={this.routeChange} className="fa fa-home fa-lg float-left"></Link>
               </h1>
               <h1>{currentStudent.fullname}</h1>
               <div className="quiz-countdown text-center text-white">
                    <h1>
               <Link  to="#" onClick={this.logOut} className="fa fa-power-off fa-lg">               </Link>
               </h1>
               logout
               </div>
          </div>  
     </div>

        );
    }
}


