import axios from "axios";


const Url = 'https://admin.zenithsciencecollege.com/accounts/api/jwt/';
class AuthService {


  login(username, password) {

    return axios.post(Url, { username, password }, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST',
        'Access-Control-Allow-Credentials': true
      },
    })

      .then(response => {
        if (response.data) {
          sessionStorage.setItem("__ZENITH_SCIENCE_COLLEGE__", JSON.stringify(response.data));
        }
        // console.log(response.data)
        return response.data;
      }

      );

  }

  
  //create new user
  createNewUser(username, password, first_name, last_name,
    // address, 
    lga, state,
    // dob,
    email, phone_number, group_pk,
    // branch_pk,
    designation_pk,
    // staff_number,
    user_type) {
    //     const mytoken = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
    // const token = mytoken.token;

    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    // formData.append('address', address);
    formData.append('lga', lga);
    // formData.append('dob', dob);
    formData.append('state', state);
    formData.append('email', email);
    formData.append('phone_number', phone_number);
    formData.append('group_pk', group_pk);
    formData.append('designation_pk', designation_pk);
    // formData.append('branch_pk', branch_pk);
    // formData.append('staff_number', staff_number);
    formData.append('user_type', user_type);



    return axios.post('https://admin.zenithsciencecollege.com/accounts/api/create-user/', formData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST, GET,HEAD,OPTIONS',
          'Access-Control-Allow-Credentials': true
        },
      }
    )
  }



  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
  }
}


export default new AuthService();
