import React, { Component } from "react";
// import AuthService from "../services/auth.service";
import { Link, Redirect } from 'react-router-dom';
import axios from "axios"
import Header from "./Header";

export default class ThankYou extends Component {
    constructor(props) {
        super(props);
      
        this.state = {
         
          loading: false,
          loadingStart: false,
          message: "",
          Exams:[]
        };
      }
    
      componentDidMount(){
      

     //  const ax = axios.create({
     //      baseURL: '../data'
     //       })
     //  ax.get('exams.json')
     //      .then(res => {
     //          if (res.data) {
     //           sessionStorage.setItem("Exams__Zenith", JSON.stringify(res.data));
     //          }
     //          const data = res.data;
     //          console.log(res.data)   

     //          this.setState({
     //              Exams: data,
     //          })
     //      });

     
      }
     

    render() {
     if (!sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__')) {
          return (<Redirect to={'/login'} />)
      }
      const userAnswered=JSON.parse(sessionStorage.getItem('Sent__Answers'));
     //    const {Exams} = this.state;
     //    const userInfo= JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          // const currentStudent=userInfo.profile
        return (
         
              <div>
                  <Header/>
                   <div className="wrapper position-relative">
                        <div className="wizard-content-1 clearfix">
                             <div className="step-inner-content clearfix position-relative">
                                  <div className="form-area position-relative">
                                       <div className="wizard-forms">
                                            <div className="quiz-option-selector">
                                                 <div className="thankyou-msg text-center">
                                                      <img src="./plugins/img/th.png" alt />
                                                      <p>{userAnswered.detail}</p>
                                                      <p> <h3 className="text-success">Total Mark Score:{userAnswered.total_mark}</h3></p>
                                                      <h6>Thanks For Taking the Exam!</h6>
                                                 </div>
                                            </div>
                                       </div>
                                  </div>
                             </div>
                        </div>
                   </div>
              </div>




        );
    }
}
