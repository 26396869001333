import React, { Component } from "react";
// import AuthService from "../services/auth.service";
import { Link, Redirect } from 'react-router-dom';
import axios from "axios"
// const required = value => {
//     if (!value) {
//         return (
//             <div className="alert alert-danger" role="alert">
//                 This field is required!
//             </div>
//         );
//     }
// };
import { SpinnerCircular, SpinnerDotted, SpinnerInfinity } from 'spinners-react';
import Header from "./Header";

export default class AddQuestion extends Component {
     constructor(props) {
          super(props);

          this.state = {

               loading: false,
               loadingStart: false,
               message: "",
               Exams: []
          };
     }


     componentDidMount() {
         
       
     }

     handleOpenModal(exam) {
          sessionStorage.setItem("DeleteQsPk", exam.pk);
        }
        
      
     StartExam(exam) {
          const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const token = user.token;
          this.setState({
               loading: true
          });

          const examPk = exam.pk
          axios.get(`https://admin.zenithsciencecollege.com/cbt/api/examination/details/${examPk}/`,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },

               },

          )
               .then(res => {
                    window.location = "/Exams"

                    if (res.data) {
                         sessionStorage.setItem("___ExamsStart___", JSON.stringify(res.data));
                    }
               })
     }

     EditQs(exam) {
          const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const token = user.token;
          this.setState({
               loading: true
          });

          const examPk = exam.pk
          axios.get(`https://admin.zenithsciencecollege.com/cbt/api/question/details/${examPk}/`,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },

               },

          )
               .then(res => {

                    if (res.data) {
                         sessionStorage.setItem("Details_of_Question", JSON.stringify(res.data));
                         window.location = "/EditQuestion"

                    }

               })
     }

     DeleteQs(exam) {

          const examPk =sessionStorage.getItem('DeleteQsPk');

          const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const token = user.token;
         
          
          axios.delete(`https://admin.zenithsciencecollege.com/cbt/api/question/delete/${examPk}/`,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },

               },

          )
               .then(res => {

                    if (res.data) {
                         window.location = "/AddExam"

                    }

               })
     }

     render() {
          
          if (!sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__')) {
               return (<Redirect to={'/login'} />)
          }
          const Examination_Questions = JSON.parse(sessionStorage.getItem('Examination_Questions'));

          const { Exams, loading } = this.state;
          const userInfo = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const currentStudent = userInfo.profile
          return (
               <div>
                    <Header />
                    <div className="wrapper position-relative">
                         <div className="wizard-content-1 clearfix">

                              <div className="step-inner-content clearfix position-relative">
                                   <form className="multisteps-form__form" action="thank-you.html" id="wizard" method="POST">
                                        <div className="form-area position-relative">
                                             {/* step 3 */}
                                             <div className="multisteps-form__panel js-active" data-animation="fadeIn">
                                                  <div className="wizard-forms">

                                                       <div className="quiz-title text-center">
                                                            <h1>ZENITH SCIENCE COLLEGE</h1>
                                                            <h4 className="text-success">{Examination_Questions.term_title}</h4>
                                                            <h6>{Examination_Questions.subject_title}</h6>
                                                            <span>{Examination_Questions.class_name_title}</span>

                                                            <p>Instructions</p>
                                                       </div>
                                                       <div className="quiz-option-selector">
                                                       {loading ? (
                                                          <center>
                                                          <SpinnerInfinity color="green" size="30%" />
                                                            </center>
                                                            
                                                     ) : (
                                                       
                                                            <div className="row">
                                                                 <div className="col-12">

                                                                 <Link to="/QuestionForm"className="btn btn-primary ">Single Upload</Link>

                                                                 <Link to="/QuestionBatchForm"className="btn btn-secondary float-right">Batch Upload</Link>
                                                                 </div>
                                                                 <br/>
                                                                 <br/>
                                                                 <div className="col-md-12">
                                                                 
                                                                      <table className="table table-bordered">
                                                                           <thead>
                                                                                <tr>
                                                                                     <th style={{ width: 10 }}>SN</th>
                                                                                     
                                                                                     <th>Body</th>
                                                                                     <th>A</th>
                                                                                     <th>B</th>
                                                                                     <th>C</th>
                                                                                     <th>D</th>
                                                                                     
                                                                                     <th>Action</th>
                                                                                     {/* <th style={{ width: 40 }}>Label</th> */}
                                                                                     {/* <th >Label</th> */}
                                                                                </tr>
                                                                           </thead>
                                                                           {Examination_Questions.get_questions && Examination_Questions.get_questions.map((exam, i) => (

                                                                           <tbody>

                                                                                <tr key={i}>
                                                                                     <td>{i+1}</td>
                                                                                     <td>{exam.body}</td>
                                                                                     <td>{exam.option_a}</td>
                                                                                     <td>{exam.option_b}</td>
                                                                                     <td>{exam.option_c}</td>
                                                                                     <td>{exam.option_d}</td>
                                                                                     
                                                                                     <td>
                                                                                          <Link to="#" onClick={this.EditQs.bind(this,exam)} className="fas fa-edit fa-lg text-success"></Link>&emsp;
                                                                                          <Link to="#" onClick={this.handleOpenModal.bind(this,exam)}  data-toggle="modal" data-target="#modal-default" className="fas fa-trash fa-lg text-danger"></Link>
                                                                                     </td>
                                                                                     {/* <td><span className="badge bg-danger">55%</span></td> */}
                                                                                </tr>
                                                                               
                                                                                <div className="modal fade show" id="modal-default"  aria-modal="true" role="dialog">
                                                                                          <div className="modal-dialog">
                                                                                               <div className="modal-content">
                                                                                                    <div className="modal-header">
                                                                                                         <h4 className="modal-title">{exam.class_name_title}</h4>
                                                                                                         <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                                              <span aria-hidden="true">×</span>
                                                                                                         </button>
                                                                                                    </div>
                                                                                                    <div className="modal-body">
                                                                                                         <p>Are you Sure you want to delete this Question</p>
                                                                                                    </div>
                                                                                                    <div className="modal-footer justify-content-between">
                                                                                                         <button type="button" className="btn btn-default" data-dismiss="modal">No</button>
                                                                                                         <button type="button" onClick={this.DeleteQs.bind(this,exam)}className="btn btn-primary">Yes</button>
                                                                                                    </div>
                                                                                               </div>
                                                                                               {/* /.modal-content */}
                                                                                          </div>
                                                                                          {/* /.modal-dialog */}
                                                                                     </div>
                                                                                {/* <tr>
                                                                                     <td>4.</td>
                                                                                     <td>Fix and squish bugs</td>
                                                                                     <td>
                                                                                          <div className="progress progress-xs progress-striped active">
                                                                                               <div className="progress-bar bg-success" style={{ width: '90%' }} />
                                                                                          </div>
                                                                                     </td>
                                                                                     <td><span className="badge bg-success">90%</span></td>
                                                                                </tr> */}
                                                                           </tbody>
                                                                           ))}
                                                                      </table>
                                                                      
                                                                 </div>
                                                            </div>
                                                        )}

                                                       </div>
                                                  </div>


                                             </div>
                                        </div>
                                   </form>
                              </div>
                         </div>
                    </div>
               </div>


          );
     }
}
