import React, { Component } from "react";
// import AuthService from "../services/auth.service";
import { Link, Redirect } from 'react-router-dom';
import axios from "axios"
import ReactModal from 'react-modal';
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalDialog from 'react-bootstrap/ModalDialog'
// const required = value => {
//     if (!value) {
//         return (
//             <div className="alert alert-danger" role="alert">
//                 This field is required!
//             </div>
//         );
//     }
// };
import { SpinnerCircular, SpinnerDotted, SpinnerInfinity } from 'spinners-react';
import Header from "./Header";

export default class AddExam extends Component {
     constructor(props) {
          super(props);

          this.state = {
               loading: true,
               loadingStart: false,
               message: "",
               Exams: [],
          };
     }

     handleOpenModal(exam) {
          sessionStorage.setItem("DeletePk", exam.pk);
        }
        
      
   
     componentDidMount() {
          this.setState({
               loading: true
          })
          const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const token = user.token;
          axios.get(`https://admin.zenithsciencecollege.com/cbt/api/examination/list/`,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },
               },
          )
               .then(res => {

                    if (res.data) {
                         sessionStorage.setItem("Exams__Zenith", JSON.stringify(res.data));
                    }

                    this.setState({
                         Exams: res.data,
                         loading: false
                    })

               })
     }

     AddQs(exam) {
          const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const token = user.token;
          this.setState({
               loading: true
          });

          const examPk = exam.pk
          axios.get(`https://admin.zenithsciencecollege.com/cbt/api/examination/details/${examPk}/`,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },

               },

          )
               .then(res => {
                    window.location = "/AddQuestion"

                    if (res.data) {
                         sessionStorage.setItem("Examination_Questions", JSON.stringify(res.data));
                    }
               })
     }

     EditExams(exam) {
          const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const token = user.token;
          this.setState({
               loading: true
          });

          const examPk = exam.pk
          axios.get(`https://admin.zenithsciencecollege.com/cbt/api/examination/details/${examPk}/`,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },

               },

          )
               .then(res => {

                    if (res.data) {
                         sessionStorage.setItem("Details_of_Examination", JSON.stringify(res.data));
                         window.location = "/EditExam"

                    }

               })
     }

     DeleteExams(exam) {

          const examPk =sessionStorage.getItem('DeletePk');

          const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const token = user.token;
         
          
          axios.delete(`https://admin.zenithsciencecollege.com/cbt/api/examination/delete/${examPk}/`,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },

               },

          )
               .then(res => {

                    if (res.data) {
                         window.location = "/AddExam"

                    }

               })
     }

     render() {
          
          if (!sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__')) {
               return (<Redirect to={'/login'} />)
          }
          const { Exams, loading ,show} = this.state;
          const userInfo = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const currentStudent = userInfo.profile
          return (
               <div>
                    <Header />
                    <div className="wrapper position-relative">
                         <div className="wizard-content-1 clearfix">

                              <div className="step-inner-content clearfix position-relative">
                                   <form className="multisteps-form__form" action="thank-you.html" id="wizard" method="POST">
                                        <div className="form-area position-relative">
                                             {/* step 3 */}
                                             <div className="multisteps-form__panel js-active" data-animation="fadeIn">
                                                  <div className="wizard-forms">
                                                       <center>       <h1>Examinations</h1></center>
                                                       <div className="quiz-option-selector">
                                                       {loading ? (
                                                          <center>
                                                          <SpinnerInfinity color="green" size="30%" />
                                                            </center>
                                                     ) : (
                                                            <div className="row">
                                                                 <div className="col-md-12">
                                                                 <Link to="/ExamForm"className="btn btn-primary float-right">Add</Link>

                                                                      <table className="table table-bordered">
                                                                           <thead>
                                                                                <tr>
                                                                                     <th style={{ width: 10 }}>SN</th>
                                                                                     
                                                                                     <th>Class</th>
                                                                                     <th>Subject</th>
                                                                                     <th>Term</th>
                                                                                     <th>Duration</th>
                                                                                     <th>Questions</th>
                                                                                     <th>Add Questions</th>
                                                                                     <th>Progress</th>
                                                                                     <th>Action</th>
                                                                                     {/* <th style={{ width: 40 }}>Label</th> */}
                                                                                     {/* <th >Label</th> */}
                                                                                </tr>
                                                                           </thead>
                                                                           {Exams && Exams.map((exam, i) => (

                                                                           <tbody>

                                                                                <tr key={i}>
                                                                                     <td>{i+1}</td>
                                                                                     <td>{exam.class_name_title}</td>
                                                                                     <td>{exam.subject_title}</td>
                                                                                     <td>{exam.term_title}</td>
                                                                                     <td>{exam.duration}</td>
                                                                                     <td>{exam.questions_count}</td>
                                                                                     <td><Link to="#" onClick={this.AddQs.bind(this,exam)} className="fas fa-plus fa-lg text-secondary"></Link></td>
                                                                                     <td>
                                                                                          <div className="progress progress-xs">
                                                                                               <div className="progress-bar progress-bar-danger" style={{ width: '55%' }} />
                                                                                          </div>
                                                                                     </td>
                                                                                     <td>
                                                                                          <Link to="#" onClick={this.EditExams.bind(this,exam)} className="fas fa-edit fa-lg text-success"></Link>&emsp;
                                                                                          <Link to="#" onClick={this.handleOpenModal.bind(this,exam)}  data-toggle="modal" data-target="#modal-default" className="fas fa-trash fa-lg text-danger"></Link>
                                                                                     </td>
                                                                                     
                                                                                     {/* <td><span className="badge bg-danger">55%</span></td> */}
                                                                                </tr>
                                                                                     
                                                                                     
                                                                                <div className="modal fade show" id="modal-default"  aria-modal="true" role="dialog">
                                                                                          <div className="modal-dialog">
                                                                                               <div className="modal-content">
                                                                                                    <div className="modal-header">
                                                                                                         <h4 className="modal-title">{exam.class_name_title}</h4>
                                                                                                         <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                                              <span aria-hidden="true">×</span>
                                                                                                         </button>
                                                                                                    </div>
                                                                                                    <div className="modal-body">
                                                                                                         <p>Are you Sure you want to delete this Exam</p>
                                                                                                    </div>
                                                                                                    <div className="modal-footer justify-content-between">
                                                                                                         <button type="button" className="btn btn-default" data-dismiss="modal">No</button>
                                                                                                         <button type="button" onClick={this.DeleteExams.bind(this,exam)}className="btn btn-primary">Yes</button>
                                                                                                    </div>
                                                                                               </div>
                                                                                               {/* /.modal-content */}
                                                                                          </div>
                                                                                          {/* /.modal-dialog */}
                                                                                     </div>
                                                                                     
                                                                                {/* <tr>
                                                                                     <td>4.</td>
                                                                                     <td>Fix and squish bugs</td>
                                                                                     <td>
                                                                                          <div className="progress progress-xs progress-striped active">
                                                                                               <div className="progress-bar bg-success" style={{ width: '90%' }} />
                                                                                          </div>
                                                                                     </td>
                                                                                     <td><span className="badge bg-success">90%</span></td>
                                                                                </tr> */}
                                                                           </tbody>
                                                                           ))}
                                                                      </table>
                                                                      
                                                                 </div>
                                                            </div>
                                                        )}

                                                       </div>
                                                  </div>


                                             </div>
                                        </div>
                                   </form>
                              </div>
                         </div>
                    </div>
               </div>


          );
     }
}
