import React, { Component } from "react";
import AuthService from "../services/auth.service";
import { Redirect } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";
import { Spinner } from "react-bootstrap";
import axios from "axios"

import { SpinnerCircular, SpinnerDotted, SpinnerInfinity } from 'spinners-react';
import Header from "./Header";

const required = value => {
     if (!value) {
          return (
               <div className="alert alert-danger" role="alert">
                    This field is required!
               </div>
          );
     }
};

export default class ExamForm extends Component {
     constructor(props) {
          super(props);
          this.handleCreate = this.handleCreate.bind(this);
          this.onChangeInstructions = this.onChangeInstructions.bind(this);
          this.onChangeDuration = this.onChangeDuration.bind(this);
          this.onChangeActive = this.onChangeActive.bind(this);
          this.onChangeTerm = this.onChangeTerm.bind(this);
          this.onChangeSubject = this.onChangeSubject.bind(this);
          this.onChangeClass_name = this.onChangeClass_name.bind(this);

          this.state = {
               username: "",
               password: "",
               loading: false,
               ViewTemplateloading: false,
               message: "",
               instructions: "",
               duration: "",
               active: "",
               term: "",
               subject: "",
               class_name: "",
               Class_List:[],
               Term_List:[],
               Subject_List:[]
          };
     }

     onChangeInstructions(e) {
          this.setState({
               instructions: e.target.value
          });
     }

     onChangeDuration(e) {
          this.setState({
               duration: e.target.value
          });
     }
     onChangeActive(e) {
          this.setState({
               active: e.target.value
          });
     }
     onChangeTerm(e) {
          this.setState({
               term: e.target.value
          });
     }
     onChangeSubject(e) {
          this.setState({
               subject: e.target.value
          });
     }
     onChangeClass_name(e) {
          this.setState({
               class_name: e.target.value
          });
     }


     CreateExam(class_name, subject, term, active,duration,instructions) {
   
          const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const token = user.token;

          let formData = new FormData();
          formData.append('class_name', class_name);
          formData.append('subject', subject);
          formData.append('term', term);
          formData.append('active', active);
          formData.append('duration', duration);
          formData.append('instructions', instructions);
         
      
          return axios.post('https://admin.zenithsciencecollege.com/cbt/api/examination/create/', formData,
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET,HEAD,OPTIONS',
                'Access-Control-Allow-Credentials': true
              },
            }
          )
        }

     handleCreate(e) {
          e.preventDefault();

          this.setState({
               message: "",
               loading: true,
               ViewTemplateloading: true,
          });

          this.form.validateAll();

          if (this.checkBtn.context._errors.length === 0) {
               this.CreateExam(
                    this.state.class_name,
                    this.state.subject,
                    this.state.term,
                    this.state.active,
                    this.state.duration,
                    this.state.instructions
               ).then(
                    () => {
                         window.location = "/AddExam"
                    },
                    error => {
                         const resMessage =
                              (error.response &&
                                   error.response.data.detail &&
                                   error.response.data.detail) ||
                              error.message ||
                              error.toString();

                         this.setState({
                              loading: false,
                              message: resMessage,
                              ViewTemplateloading: false,

                         });
                    }
               );
          } else {
               this.setState({
                    loading: false
               });
          }
     }

     componentDidMount() {
          this.setState({
               loading: true
          })
          const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const token = user.token;
          axios.get(`https://admin.zenithsciencecollege.com/cbt/api/examination/list/`,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },

               },
          )
               .then(res => {

                    if (res.data) {
                         sessionStorage.setItem("Exams__Zenith", JSON.stringify(res.data));
                    }

                    this.setState({
                         Exams: res.data,
                         loading: false
                    })

               })

               //term
               axios.get(`https://admin.zenithsciencecollege.com/academics/api/term/list/?active=yes`,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },

               },
          )
               .then(res => {

                    this.setState({
                         Term_List: res.data,
                         loading: false
                    })

               })
                    //classes
               axios.get(`https://admin.zenithsciencecollege.com/academics/api/class/list/?active=yes`,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },

               },
          )
               .then(res => {

                    this.setState({
                         Class_List: res.data,
                         loading: false
                    })

               })
                  //subject
                  axios.get(`https://admin.zenithsciencecollege.com/academics/api/subject/list/?active=yes`,
                  {
                       headers:
                       {
                            'Content-Type': 'application/json',
                            'Authorization': `Token ${token}`,
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                            'Access-Control-Allow-Credentials': true
                       },
   
                  },
             )
                  .then(res => {
   
                       this.setState({
                            Subject_List: res.data,
                            loading: false
                       })
   
                  })

     }


     render() {
          if (!sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__')) {
               return (<Redirect to={'/login'} />)
          }
          const { Exams, loading,Class_List,Term_List,Subject_List } = this.state;
          const userInfo = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const currentStudent = userInfo.profile
          return (
               <div>
                    <Header />
                    <div className="wrapper position-relative">
                         <div className="wizard-content-1 clearfix">

                              <div className="step-inner-content clearfix position-relative">
                                   <Form className="multisteps-form__form"
                                        onSubmit={this.handleCreate}
                                        ref={c => {
                                             this.form = c;
                                        }}
                                        id="wizard" >
                                       
                                        <div className="form-area position-relative">
                                             {/* step 3 */}

                                             <div className="multisteps-form__panel js-active" data-animation="fadeIn">
                                                  <div className="col-md-12">
                                                       <div className="job-wizard-right-area">
                                                       {this.state.message && (
                                             <div className="form-group">
                                                  <div className="text-danger" >
                                                      <h4> {this.state.message}</h4>
                                                  </div>
                                             </div>
                                        )}
                                                            <div className="jw-top-title">
                                                                 <h2>Please Fill in the Form</h2>
                                                                 <p>Examination info</p>
                                                            </div>
                                                            <div className="hire-company-form">
                                                                 <div className="job-app-select-option">
                                                                      <ul>

                                                                           <li>
                                                                                <Select name="j_position"
                                                                                     className="valid"
                                                                                     aria-invalid="false"
                                                                                     value={this.state.class_name} onChange={this.onChangeClass_name} validations={[required]}

                                                                                >
                                                                                  
                                                                                     <option>Select Class</option>
                                                                                     {Class_List && Class_List.map((classes, index) => (
                                                                                          <option value={classes.pk} key={index}>{classes.name}</option>
                                                                                     ))}
                                                                                </Select>
                                                                           </li>
                                                                      </ul>
                                                                      <ul>

                                                                           <li>
                                                                                <Select name="j_position"
                                                                                     className="valid"
                                                                                     aria-invalid="false"
                                                                                     value={this.state.subject} onChange={this.onChangeSubject} validations={[required]}
                                                                                >
                                                                                     <option>Select Subject</option>
                                                                                     {Subject_List && Subject_List.map((subjects, index) => (
                                                                                          <option value={subjects.pk} key={index}>{subjects.name}</option>
                                                                                     ))}
                                                                                </Select>
                                                                           </li>
                                                                      </ul>
                                                                      <ul>

                                                                           <li>
                                                                                <Select name="j_position"
                                                                                     className="valid"
                                                                                     aria-invalid="false"
                                                                                     value={this.state.term} onChange={this.onChangeTerm} validations={[required]}

                                                                                >
                                                                                     <option>Select Term</option>
                                                                                     {Term_List && Term_List.map((terms, index) => (
                                                                                          <option value={terms.pk} key={index}>{terms.name}</option>
                                                                                     ))}
                                                                                </Select>
                                                                           </li>
                                                                      </ul>
                                                                      <ul>

                                                                           <li>
                                                                                <Select name="j_position"
                                                                                     className="valid"
                                                                                     aria-invalid="false"
                                                                                     value={this.state.active} onChange={this.onChangeActive} validations={[required]}

                                                                                >
                                                                                     <option>Status</option>
                                                                                     <option value="yes">Active</option>
                                                                                     <option value="no">Inactive</option>
                                                                                
                                                                                </Select>
                                                                           </li>
                                                                      </ul>
                                                                      <ul>

                                                                           <li>
                                                                                <Select name="j_position"
                                                                                     className="valid"
                                                                                     aria-invalid="false"
                                                                                     value={this.state.duration} onChange={this.onChangeDuration} validations={[required]}

                                                                                >
                                                                                    

                                                                                     <option>Select Duration</option>
                                                                                     <option value="30_minutes">30 Minutes</option>
                                                                                     <option value="60_minutes">60 Minutes</option>
                                                                                     <option value="120_minutes">1 Hour</option>
                                                                                    
                                                                                </Select>
                                                                           </li>
                                                                      </ul>
                                                                 </div>

                                                                 <div className="word-txt-area">
                                                                      <Textarea name="some_word" 
                                                                      placeholder="Write Some instructions"
                                                                      value={this.state.instructions} onChange={this.onChangeInstructions} validations={[required]}
                                                                       
                                                                       />
                                                                 </div>

                                                            </div>


                                                            <button className="btn btn-primary btn-lg btn-block">

                                                                 {this.state.loading ?
                                                                      <Spinner animation="border" role="status">
                                                                           <span className="visually-hidden">loading...</span>
                                                                      </Spinner>
                                                                      :
                                                                      <span>ADD</span>
                                                                 }
                                                            </button>
                                                       </div>

                                                  </div>


                                             </div>
                                        </div>
                                        <CheckButton
                                             style={{ display: "none" }}
                                             ref={c => {
                                                  this.checkBtn = c;
                                             }}
                                        />
                                   </Form>
                              </div>
                         </div>
                    </div>
               </div>


          );
     }
}