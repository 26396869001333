import React, { useContext }  from 'react';
import { FormContext } from '../FormContext';

  
 
const OptionInput = ({pk, body,correct_option,examination,option_a,option_b,option_c,option_d,option_e, answer}) => {
    // console.log(field_value)
    const  {handleChange}  = useContext(FormContext)

    return (

        <div>
        <div className="quiz-option-selector clearfix">
          <ul>
            <li>
              <label className="start-quiz-item">
                <input type="radio"
                  name="quiz"
                  className="exp-option-box"
                  onChange={event => handleChange(pk, event)}
                  value="a"
                />
                <span className="exp-label">{option_a}</span>

                <span className="checkmark-border" />
                <span className="exp-number text-uppercase">A</span>

              </label>
            </li>
            <li>
              <label>
              <input type="radio"
                  name="quiz"
                  className="exp-option-box"
                  onChange={event => handleChange(pk, event)}
                  value="b"
                />
                <span className="exp-label">{option_b}</span>

                <span className="checkmark-border" />
                 <span className="exp-number text-uppercase">b</span>
              </label>
            </li>
            <li>
              <label>
              <input type="radio"
                  name="quiz"
                  className="exp-option-box"
                  onChange={event => handleChange(pk, event)}
                  value="c"
                />
                <span className="exp-label">{option_c}</span>

                <span className="checkmark-border" />
                  <span className="exp-number text-uppercase">c</span>
              </label>
            </li>
            <li>
              <label>
              <input type="radio"
                  name="quiz"
                  className="exp-option-box"
                  onChange={event => handleChange(pk, event)}
                  value="d"
                />
                <span className="exp-label">{option_d}</span>

                <span className="checkmark-border" />
              <span className="exp-number text-uppercase">d</span>
              </label>
            </li>
          </ul>
        </div>

     
     
         </div>
         
                             
                  

    )
}

export default OptionInput
