import React, { Component } from "react";
// import AuthService from "../services/auth.service";
import { Link, Redirect } from 'react-router-dom';
import axios from "axios"
// const required = value => {
//     if (!value) {
//         return (
//             <div className="alert alert-danger" role="alert">
//                 This field is required!
//             </div>
//         );
//     }
// };
import { SpinnerCircular, SpinnerDotted,SpinnerInfinity } from 'spinners-react';
import Header from "./Header";

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
      
        this.state = {
         
          loading: true,
          loadingStart: false,
          message: "",
          Exams:[],
          student: false,
          teacher: false,
        };
      }
    

      componentDidMount(){
      
      const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
      const token =user.token;
      const class_pk=user.profile.class_name;
      
    if (user) {
      this.setState({
        student: user.profile.user_groups.includes("Student")|| user.profile.user_type==="student",
        teacher: user.profile.user_groups.includes("Teacher")|| user.profile.user_type==="staff",
        loading: true

         
      });
    }
if(class_pk!=null){
      axios.get(`https://admin.zenithsciencecollege.com/cbt/api/examination/list/?class_pk=${class_pk}&active=yes`,
           {
                headers:
                {
                     'Content-Type': 'application/json',
                     'Authorization': `Token ${token}`,
                     'Access-Control-Allow-Origin': '*',
                     'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                     'Access-Control-Allow-Credentials': true
                },

           },
      )
           .then(res => {

                if (res.data) {
                     sessionStorage.setItem("Exams__Zenith", JSON.stringify(res.data));
                }
                
                this.setState({
                                 Exams: res.data,
                                 loading:false
                        })
               
           })

     //  const ax = axios.create({
     //      baseURL: '../data'
     //       })
     //  ax.get('exams.json')
     //      .then(res => {
     //          if (res.data) {
     //           sessionStorage.setItem("Exams__Zenith", JSON.stringify(res.data));
     //          }
     //          const data = res.data;
     //          console.log(res.data)   

     //          this.setState({
     //              Exams: data,
     //          })
     //      });
          }
     
      }
      StartExam(exam) {
          const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const token=user.token;
          this.setState({
               loading:true
          });

          const examPk = exam.pk
          axios.get(`https://admin.zenithsciencecollege.com/cbt/api/examination/details/${examPk}/`,
               {
                    headers:
                    {
                         'Content-Type': 'application/json',
                         'Authorization': `Token ${token}`,
                         'Access-Control-Allow-Origin': '*',
                         'Access-Control-Allow-Methods': 'GET,POST,HEAD,OPTIONS',
                         'Access-Control-Allow-Credentials': true
                    },

               },

          )
               .then(res => {
                    window.location = "/Exams"

                    if (res.data) {
                         sessionStorage.setItem("___ExamsStart___", JSON.stringify(res.data));
                    }
               })
     }


    render() {
     if (!sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__')) {
          return (<Redirect to={'/login'} />)
      }
        const {Exams,loading,teacher,student} = this.state;
        const userInfo= JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
          const currentStudent=userInfo.profile
        return (
          <div>
         <Header/>
          <div className="wrapper position-relative">
               <div className="wizard-content-1 clearfix">
                    
                    <div className="step-inner-content clearfix position-relative">
                         <form className="multisteps-form__form" action="thank-you.html" id="wizard" method="POST">
                              <div className="form-area position-relative">

                                   
                                   {/* step 3 */}
                                   <div className="multisteps-form__panel js-active" data-animation="fadeIn">
                                   {teacher &&(
                                   <div className="wizard-forms">
                                  <center>       <h1>Examinations</h1></center>

                                   <div className="quiz-option-selector">

                                                  <div className="row">
                                                  <div  className="col-md-3">
                                                            <label className="quiz-option-area position-relative">
                                                                 <input type="radio" name="quiz" defaultValue="SEO" className="exp-option-box" />
                                                                 <span className="quiz-option-img">
                                                                      <Link to="/ViewExam" >
                                                                      <img src="./plugins/img/list-rem.png" height="70px" alt="" />
                                                                      </Link>
                                                                 </span>
                                                                 {/* <span className="quiz-option-serial text-center"></span> */}
                                                                 <span className="quiz-option-text text-primary">
                                                                 <Link to="/ViewExam"  >
                                                                 <h1 > <strong> EXAMS</strong></h1>
                                                                 </Link>
                                                                 </span>

                                                            </label>
                                                       </div>
                                                       &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                                       &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                                      
                                                       <div  className="col-md-5">
                                                            <label className="quiz-option-area position-relative">
                                                                 <input type="radio" name="quiz" defaultValue="SEO" className="exp-option-box" />
                                                                 <span className="quiz-option-img">
                                                                      <Link to="/AddExam"  >
                                                                      <img src="./plugins/img/add.png" alt="" />
                                                                      </Link>
                                                                 </span>
                                                                 {/* <span className="quiz-option-serial text-center"></span> */}
                                                                 <span className="quiz-option-text text-center text-success">
                                                                 <Link to="/AddExam"  >
                                                                 <h1 ><strong>CREATE EXAM</strong></h1>
                                                                 </Link>
                                                                 </span>

                                                            </label>
                                                       </div>
                                                       
                                                  </div>
                                             </div>
                                   </div>
                                   )}
                                 {student &&(

                                        <div className="wizard-forms">
                                             <div className="quiz-title text-center">
                                                  <span>{currentStudent.class_name_title}</span>
                                                  <h2>Start Exam</h2>
                                             </div>
                                                     
                                             <div className="quiz-option-selector">
                                             {loading ? (
                                                          <center>
                                                          <SpinnerInfinity color="green" size="30%" />
                                                            </center>
                                                     ) : (
                                                  <div className="row">
                                                  {Exams && Exams.map((exam, i) => (
                                                       <div key={i} className="col-md-3">
                                                            <label className="quiz-option-area position-relative">
                                                                 <input type="radio" name="quiz" defaultValue="SEO" className="exp-option-box" />
                                                                 <span className="quiz-option-img">
                                                                      <Link to=""  onClick={this.StartExam.bind(this, exam)}>
                                                                      <img src="./plugins/img/exam.png" alt="" />
                                                                      </Link>
                                                                 </span>
                                                                 <span className="quiz-option-serial text-center">{i+1}</span>
                                                                 <span className="quiz-option-text">
                                                                 <Link to=""  onClick={this.StartExam.bind(this, exam)}>
                                                                 <h5 >{exam.subject_title}</h5>
                                                                 </Link>
                                                                 </span>

                                                            </label>
                                                       </div>
                                                  ))}
                                                  </div>
                                                     )}
                                             </div>
                                          
                                             
                                            
                                           
                                        </div>
                                 )}
                                   </div>
                              </div>
                         </form>
                    </div>
               </div>
          </div>
     </div>


        );
    }
}
