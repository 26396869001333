import React, { Component } from "react";
import * as XLSX from 'xlsx'
import Header from "./Header";
// import { SpinnerCircular, SpinnerDotted, SpinnerInfinity } from 'spinners-react';
import { Link, Redirect } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import axios from "axios"


export default class QuestionBatchForm extends Component {

  constructor(props) {
    super(props);
    this.addTobatches = this.addTobatches.bind(this);

    this.state = {
      file: "",
      data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
      cols: [], /* Array of column objects e.g. { name: "C", K: 2 } */
      message: "",
      loading: false
    };
  }

  filePathset(e) {
    const files = e.target.files;
    if (files && files[0]) this.handleFile(files[0]);
    e.stopPropagation();
    e.preventDefault();


    var file = e.target.files[0];
    // console.log(file);
    this.readFile(file)
    this.setState({ file });
    // console.log(this.state.file);
  }

  readFile(file) {
    var f = file;
    var name = f.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      //   console.log("Data>>>" + data);// shows that excel data is read
      //   console.log(this.convertToJson(data)); // shows data in json format
      sessionStorage.setItem("___ExamsInBatches", this.convertToJson(data));



    };
    reader.readAsBinaryString(f);
  }

  convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  handleFile(file /*:File*/) {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // console.log(rABS, wb);
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      this.setState({ data: data, cols: make_cols(ws["!ref"]) });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }

  addTobatches(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    const user = JSON.parse(sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__'));
    const token = user.token;
    const current_exam = JSON.parse(sessionStorage.getItem('Examination_Questions'));
    const batches = JSON.parse(sessionStorage.getItem('___ExamsInBatches'));
    batches.pop(); // last element removed

    let formData = new FormData();

    for (let i = 0; i < batches.length; i++) {
      const QuestionValue = batches[i];
      formData.append('examination', current_exam.pk);
      formData.append('body', QuestionValue.body);
      formData.append('option_a', QuestionValue.option_a);
      formData.append('option_b', QuestionValue.option_b);
      formData.append('option_c', QuestionValue.option_c);
      formData.append('option_d', QuestionValue.option_d);
      formData.append('option_e', "option_constant");
      formData.append('correct_option', QuestionValue.correct_option);

      // const body =[

      //     {
      //       "examination": current_exam.pk,
      //       "body": QuestionValue.body,
      //       "option_a":QuestionValue.option_a,
      //       "option_b": QuestionValue.option_b,
      //       "option_c": QuestionValue.option_c,
      //       "option_d": QuestionValue.option_d,
      //       "option_e": "option_constant",
      //       "correct_option": QuestionValue.correct_option
      //     }
      //   ]
     
      // batches.forEach((batch, index) => {
      //   formData.append(`exam[${index}].examination`, current_exam.pk);
      //   formData.append(`exam[${index}].body`, batch.body);
      //   formData.append(`exam[${index}].option_a`, batch.option_a);
      //   formData.append(`exam[${index}].option_b`, batch.option_b);
      //   formData.append(`exam[${index}].option_c`, batch.option_c);
      //   formData.append(`exam[${index}].option_d`, batch.option_d);
      //   formData.append(`exam[${index}].option_e`, "option_constant");
      //   formData.append(`exam[${index}].correct_option`, batch.correct_option);

      //   // Append images
      //   // person.images.forEach((image, imageIndex) =>
      //   //   data.append(`people[${index}].images`, {
      //   //     name: 'image' + imageIndex,
      //   //     type: 'image/jpeg',
      //   //     uri: image,
      //   //   })
      //   // );
      // });

      // console.log(formData)
      axios.post('https://admin.zenithsciencecollege.com/cbt/api/question/create/', formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST, GET,HEAD,OPTIONS',
          'Access-Control-Allow-Credentials': true
        }
      },
      )
        .then(
          response => {
            this.setState({
              Message: "Added to batches Successefully",
              successful: true,
              loading: false
            });


            window.location = "/AddExam"
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data.details &&
                error.response.data.details) ||
              error.message ||
              error.toString();

            this.setState({
              loading: false,
              Message: resMessage,

            });
          }
        );


    }


  }
  render() {

    return (
      <div>

        <Header />
        <form className="multisteps-form__form"
          id="wizard"
          onSubmit={this.addTobatches}
        >
          <div className="form-area position-relative">
            {/* step 3 */}
            <div className="multisteps-form__panel js-active" data-animation="fadeIn">
              <div className="wizard-forms">
                <div className="quiz-option-selector">
                  <div className="row">

                    <div className="col-md-12">

                      <input
                        type="file"
                        className="form-control"
                        id="file"
                        ref="fileUploader"
                        accept={SheetJSFT}
                        onChange={this.filePathset.bind(this)}

                      />

                    </div>
                    <br />
                    <br />
                    <br />


                    <div className="col-md-12">
                      <OutTable data={this.state.data} cols={this.state.cols} />
                    </div>
                  </div>
                  <button className="btn btn-info btn-lg btn-block">

                    {this.state.loading ?
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">loading...</span>
                      </Spinner>
                      :
                      <span>PROCESS</span>
                    }
                  </button>

                </div>
              </div>


            </div>
          </div>
        </form>
      </div>
    );
  }
}


class OutTable extends React.Component {
  render() {
    return (


      <table className="table table-bordered">

        <thead>
          <tr>
            {/* <th>  SN</th>
            <th>  Body</th>
            <th>  A</th>
            <th>  B</th>
            <th>  C</th>
            <th>  D</th> */}



          </tr>

        </thead>

        <tbody>

          {this.props.data.map((r, i) => (
            <tr key={i}>
              {this.props.cols.map(c => (
                <td key={c.key}>{r[c.key]}</td>
              ))}
            </tr>
          ))}



        </tbody>
      </table>



    );
  }
}




/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm"
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

/* generate an array of column objects */
const make_cols = refstr => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};

