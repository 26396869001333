import React, { Component } from "react";
import AuthService from "../services/auth.service";
import { Redirect } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Spinner } from "react-bootstrap";
// import './login/css/bootstrap.min.css'
// import './login/css/owl.carousel.css'
// import './login/css/animate.min.css'
// import './login/css/fontawesome-all.css'
// import './login/css/style.css'


const required = value => {
     if (!value) {
          return (
               <div className="alert alert-danger" role="alert">
                    This field is required!
               </div>
          );
     }
};

export default class Login extends Component {
     constructor(props) {
          super(props);
          this.handleLogin = this.handleLogin.bind(this);
          this.onChangeUsername = this.onChangeUsername.bind(this);
          this.onChangePassword = this.onChangePassword.bind(this);

          this.state = {
               username: "",
               password: "",
               loading: false,
               ViewTemplateloading: false,
               message: ""
          };
     }

     onChangeUsername(e) {
          this.setState({
               username: e.target.value
          });
     }

     onChangePassword(e) {
          this.setState({
               password: e.target.value
          });
     }

     componentDidMount() {
          //  js
          //     const loadScript = function (src) {
          //       const tag = document.createElement('script');
          //       tag.async = false;
          //       tag.src = src;
          //       document.body.appendChild(tag);
          //     }

          //     loadScript('./login/js/jquery-3.3.1.min.js')
          //     loadScript('./login/js/bootstrap.min.js')
          //     loadScript('./login/js/popper.min.js')
          //     loadScript('./login/js/owl.carousel.min.js')
          //     loadScript('./login/js/form-step.js')
          //     loadScript('./login/js/jquery.validate.min.js')
          //     loadScript('./login/js/validate.js')
          //     loadScript('./login/js/contact.js')
          //     loadScript('./login/js/main.js')


     }
     handleLogin(e) {
          e.preventDefault();

          this.setState({
               message: "",
               loading: true,
               ViewTemplateloading: true,
          });

          this.form.validateAll();

          if (this.checkBtn.context._errors.length === 0) {
               AuthService.login(this.state.username, this.state.password).then(
                    () => {
                         window.location = "/dashboard"
                    },
                    error => {
                         const resMessage =
                              (error.response &&
                                   error.response.data.non_field_errors &&
                                   error.response.data.non_field_errors) ||
                              error.message ||
                              error.toString();

                         this.setState({
                              loading: false,
                              message: resMessage,
                              ViewTemplateloading: false,

                         });
                    }
               );
          } else {
               this.setState({
                    loading: false
               });
          }
     }


     render() {
          if (sessionStorage.getItem('__ZENITH_SCIENCE_COLLEGE__')) {
               return (<Redirect to={'/dashboard'} />)
          }
          // const {loading} = this.state;

          return (
               <div className="wrapper position-relative">
                    <div className="wizard-content-1 clearfix">

                         <div className="step-inner-content clearfix position-relative">
                              <Form className="multisteps-form__form"
                                   onSubmit={this.handleLogin}
                                   ref={c => {
                                        this.form = c;
                                   }}
                                   id="wizard" >

                                   <div className="form-area position-relative">

                                        <div className="multisteps-form__panel  js-active" data-animation="fadeIn">
                                             <div className="wizard-forms position-relative">
                                                  {this.state.message && (
                                                       <div className="form-group">
                                                            <div className="text-primary ml-2" >
                                                                 {this.state.message}
                                                            </div>
                                                       </div>
                                                  )}
                                                  <div className="job-style-two-content d-flex">

                                                       <div className="job-wizard-left-area position-relative">

                                                            <div className="job-wizard-left-text">
                                                                 <h1>Welcome to Zenith Science College
                                                                 </h1>
                                                                 <p>A community of lifelong learners, responsible global citizens, and champions of our own success.</p>
                                                            </div>
                                                       </div>
                                                       <div className="job-wizard-right-area">
                                                            <div className="jw-top-title">
                                                                 <h2>Welcome Back</h2>
                                                                 <p>Please login with your Credentials</p>
                                                            </div>
                                                            <div className="hire-company-form">
                                                                 <Input className="required" type="text" name="company_name" placeholder="Username" value={this.state.username} onChange={this.onChangeUsername} validations={[required]} />
                                                                 <Input className="required" type="password" name="company_location" placeholder="Password" value={this.state.password} onChange={this.onChangePassword} validations={[required]} />
                                                            </div>

                                                            <button className="btn btn-success btn-lg btn-block">

                                                                 {this.state.loading ?
                                                                      <Spinner animation="border" role="status">
                                                                           <span className="visually-hidden">loading...</span>
                                                                      </Spinner>
                                                                      :
                                                                      <span>LOGIN</span>
                                                                 }
                                                            </button>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        {/* step 1 */}

                                   </div>
                                   <CheckButton
                                        style={{ display: "none" }}
                                        ref={c => {
                                             this.checkBtn = c;
                                        }}
                                   />
                              </Form>
                         </div>
                    </div>
               </div>



          );
     }
}
