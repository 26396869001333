import React from 'react'
import {  Route,Switch } from 'react-router-dom';
import Login from './Login';
import Exams from './Exams';
import Dashboard from './Dashboard';
import ThankYou from './ThankYou';
import AddExam from './AddExam';
import ExamForm from './ExamForm';
import ViewExam from './ViewExam';
import AddQuestion from './AddQuestion';
import QuestionForm from './QuestionForm';
import EditExam from './EditExam';
import EditQuestion from './EditQuestion';
import QuestionBatchForm from './QuestionBatchForm';








const  Content = () => {

    return (
       <Switch>
          
          <Route exact path="/" component={Login}/>
           <Route  path="/login" component={Login}/>
           
           <Route exact path="/" component={Exams}/>
           <Route  path="/Exams" component={Exams}/>

           <Route exact path="/" component={Dashboard}/>
           <Route  path="/Dashboard" component={Dashboard}/>

           <Route exact path="/" component={ThankYou}/>
           <Route  path="/ThankYou" component={ThankYou}/>
   
           <Route exact path="/" component={AddExam}/>
           <Route  path="/AddExam" component={AddExam}/>

           <Route exact path="/" component={ExamForm}/>
           <Route  path="/ExamForm" component={ExamForm}/>

           <Route exact path="/" component={ViewExam}/>
           <Route  path="/ViewExam" component={ViewExam}/>

           <Route exact path="/" component={AddQuestion}/>
           <Route  path="/AddQuestion" component={AddQuestion}/>

           
           <Route exact path="/" component={QuestionForm}/>
           <Route  path="/QuestionForm" component={QuestionForm}/>

           
           
           <Route exact path="/" component={EditExam}/>
           <Route  path="/EditExam" component={EditExam}/>

           <Route exact path="/" component={EditQuestion}/>
           <Route  path="/EditQuestion" component={EditQuestion}/>

           
           <Route exact path="/" component={QuestionBatchForm}/>
           <Route  path="/QuestionBatchForm" component={QuestionBatchForm}/>

           
           
       </Switch>

       
    )
}


export default Content;
